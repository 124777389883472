import axios, { AxiosRequestConfig } from "axios";

type HttpRequest = {
    url: string,
    method?: "get" | "post" | "put" | "delete" | "GET" | "POST" | "PUT" | "DELETE",
    auth?: boolean,
    body?: any,
    params?: any,
    token?: string
};

export type APIResponse<T> = {
    error: boolean,
    code: number,
    msg: string,
    data: T
};

export default class BaseService {

    static async perform<T>(request: HttpRequest): Promise<APIResponse<T>> {
        const config: AxiosRequestConfig = {
            baseURL: process.env.VUE_APP_URL_BACKEND,
            url: request.url,
            method: request.method,
            headers: {
                "Accept-Version": "1.0.0"
            },
            data: request.body,
            params: request.params
        };

        // if (request.auth) {
        //     config.headers!["Authorization"] = `Bearer ${request.token ?? localStorage.getItem("access-token")}`;
        // }
        if( sessionStorage.getItem("access_token") !== null)
            config.headers!["access_token"] = sessionStorage.getItem("access_token")!;


        const response = (await axios(config)).data as APIResponse<T>;
      setTimeout(()=>{
        if(response.error && response.code === 401){
          sessionStorage.removeItem("access_token")
          location.href = '/';
        }},2000)
        return response;
    }

}